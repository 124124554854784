import React, {Component, Suspense, lazy} from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import AsideMenu from "./AsideMenu/AsideMenu";

const Universe = lazy(() => import("./Universe"));

const Home = lazy(() => import("./Home"));
const Portfolio = lazy(() => import("./Portfolio"));
const NotFound = lazy(() => import("./NotFound"));
const Demos = lazy(() => import("./Demos"));

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Suspense fallback={""}>
                    <Universe galaxiesNo={70} />
                </Suspense>
                <Suspense fallback={""}>
                    <Switch>
                        <Route exact path={"/"}>
                            <Home/>
                        </Route>
                        <Route path={"/portfolio"}>
                            <Portfolio/>
                        </Route>
                        <Route path={"/demo/:name"}>
                            <Demos />
                        </Route>
                        <Route>
                            <NotFound/>
                        </Route>
                    </Switch>
                </Suspense>
                <AsideMenu/>
            </BrowserRouter>
        );
    }
}

export default App;
