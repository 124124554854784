import React from "react";
import PropTypes from "prop-types";
import "./aside.css"
import {Link} from "react-router-dom";

function AsideMenu({menuItems}) {
    return (
        <nav className={"nav"}>
            <ul>
                {menuItems.map((item) => (
                    <li key={item.title} className={"menu-item"}>
                        {item.uriType === "external" && <a href={item.uri} className={`link-${item.uriType}`}>{item.title}</a> }
                        {item.uriType === "internal" && <Link to={item.uri} className={`link-${item.uriType}`}>{item.title}</Link> }
                    </li>
                ))}
            </ul>
        </nav>
    )
}

AsideMenu.defaultProps = {
    menuItems: [
        {
            title: "Home",
            uri: "/",
            uriType: "internal"
        },
        {
            title: "Portfolio",
            uri: "/portfolio",
            uriType: "internal"
        },
        {
            title: "Blog",
            uri: "https://blog.stefanscript.com/",
            uriType: "external"
        }
    ]
};

AsideMenu.propTypes = {
    menuItems: PropTypes.array
};

export default AsideMenu;
